// 
// nav.scss
//

.nav-tabs,.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $label-font-weight;
        }
    }
}

.nav-pills {
    > a {
        color: $gray-700;
        font-weight: $label-font-weight;   
    }
}


//Navtab custom
.navtab-bg {
    background-color: $card-cap-bg;
}


// Nav bordered

.nav-bordered {
    border-bottom: 2px solid rgba($gray-600, 0.2) !important;
    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border: 0 !important;
            padding: 10px 20px;
        }
    }

a.active {
    border-bottom: 2px solid $primary !important;
}
}

.tab-content {
    padding: 20px 0 0 0;
}  