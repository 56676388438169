// 
// pagination.scss
//


// Pagination split (Custom)

.pagination-split {
    li {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
      a {
        border-radius: 3px;
      }
    }
}